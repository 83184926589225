import nanoid from "nanoid";
import { IboEx } from "../common/Utils";
import { Ibo, Market } from "../graphql/generated/graphql";
import { IboEditable } from "./CalcMain";

export class IboService {
  static calcTotalBv(ibo: IboEx | null | undefined, market: Market) {
    if (!ibo) return 0;
    ibo.totalPv = Math.round(ibo.selfBv / market.bvPvRatio);
    const children = ibo.downlines;
    if (children === undefined || children.length === 0) {
      ibo.totalBv = ibo.selfBv;
    } else {
      let thisTotalBv = ibo.selfBv;
      for (const iboDl of children) {
        thisTotalBv += this.calcTotalBv(iboDl, market);
      }
      ibo.totalBv = thisTotalBv;
    }
    ibo.totalPv = Math.floor(ibo.totalBv / market.bvPvRatio);
    ibo.bonusRate = this.getBonusRate(ibo.totalBv, market)!;
    return ibo.totalBv;
  }

  static calcBonus = (ibo: IboEx | null) => {
    if (!ibo || !ibo.bonusRate) return;
    if (!ibo.downlines || ibo.downlines.length === 0) {
      ibo.bonus = Math.round(ibo.totalBv! * ibo.bonusRate);
    } else {
      var allBonus = ibo.totalBv! * ibo.bonusRate;
      for (var i in ibo.downlines) {
        IboService.calcBonus(ibo.downlines[i]);
        allBonus -= ibo.downlines[i].totalBv! * ibo.downlines[i].bonusRate!;
      }
      ibo.bonus = Math.round(allBonus);
    }
  };

  static getBonusRate(totalBv: number, market: Market) {
    for (const bs of market.schedules!.perfSchedules!) {
      if (totalBv >= Math.floor(bs!.pv! * market.bvPvRatio!)) {
        return bs!.rate;
      }
    }
    return 0;
  }

  static populateTree(ibos: Ibo[]) {
    const iboExs: IboEx[] = ibos.map(i => {
      return { ...i } as IboEx;
    });
    let ret: IboEx = { id: "", name: "", selfBv: 0 };
    for (const ibo of iboExs) {
      if (!!!ibo.parentId) ret = ibo;
      else {
        const p = iboExs.find(u => u.id === ibo.parentId);
        if (p && p.downlines === undefined) {
          p.downlines = [];
        }
        p && p.downlines && p.downlines.push(ibo);
      }
    }
    return ret;
  }

  // static flatTree(iboEx: IboEx) {
  //   let ret: Ibo[] = [];
  //   ret.push({ ...iboEx });
  //   if (!iboEx.downlines) {
  //     return ret;
  //   } else {
  //     for (const dl of iboEx.downlines) {
  //       ret = ret.concat(this.flatTree(dl));
  //     }
  //   }
  //   return ret;
  // }

  static updateIboInfo = (iboList: Ibo[], updateIbvId: string, iboInfo: IboEditable) => {
    return iboList.map(ibo => {
      if (ibo.id === updateIbvId) {
        ibo = { ...ibo, name: iboInfo.name, selfBv: iboInfo.selfBv, avatar: iboInfo.avatar };
      }
      return ibo;
    });
  };

  static deleteIbo = (iboList: Ibo[], deleteIboId: string) => {
    if (!iboList || !iboList.length || iboList.length === 1) return iboList;
    let tempIboList = [...iboList];
    IboService.deleteIboRecru(tempIboList, deleteIboId);
    return tempIboList;
  };

  static deleteIboRecru = (iboList: Ibo[], deleteIboId: string) => {
    iboList.splice(
      iboList.findIndex(i => i.id === deleteIboId),
      1
    );
    const toBeDel = iboList.filter(ibo => ibo.parentId && deleteIboId === ibo.parentId);
    if (!toBeDel || !toBeDel.length) return;
    iboList.splice(iboList.findIndex(i => i.parentId === deleteIboId));
    for (const i of toBeDel) IboService.deleteIbo(iboList, i.id!);
  };

  static addIbo = (iboList: Ibo[], addToIboId: string) => {
    return [
      ...iboList,
      {
        id: nanoid(16),
        name: "NewIbo" + iboList.length,
        selfBv: 0,
        parentId: addToIboId
      }
    ];
  };
}
