import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import nanoid from "nanoid";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, PinterestIcon, PinterestShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, VKIcon, VKShareButton, VKShareCount, WhatsappIcon, WhatsappShareButton } from "react-share";
import { toast } from "react-toastify";
import store from "store2";
import { ChartFormDialog } from "../chart/ChartFormDialog";
import { LSKEY_USER_ID, URL_PATH_CALC } from "../common/Constants";
import { initialData } from "../common/Utils";
import { Chart, Ibo, useSaveChartMutation } from "../graphql/generated/graphql";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

interface Props {
  chart: Chart;
  iboList: Ibo[];
  refetchChart: (variables?: any) => void;
}

export const CalcHeader: React.FC<Props> = props => {
  const classes = useStyles();
  const history = useHistory();
  const { calChartId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [saveChart, { loading }] = useSaveChartMutation({
    onCompleted: d => {
      if (d && d.saveChart && d.saveChart.id){
        toast.success("Save Calculator Successfully. ");
        history.replace(`${URL_PATH_CALC}/${d.saveChart!.market!.urlSlug}/${d.saveChart!.id}`);
      }
    }
  });
  const chart = props.chart;
  const { market, ownerId, ...rest } = chart;
  const onSave = () => {
    const iboList = props.iboList;
    saveChart({
      variables: {
        chart: {
          ...rest,
          id: ownerId ? chart.id : nanoid(16),
          ownerId: store.get(LSKEY_USER_ID),
          ibos: { iboList: iboList ? iboList : initialData }
        },
        isCreate: !chart || !ownerId || !chart.dateCreated
      }
    });
  };
  if (!props.chart) return null;

  const socialButtonSize = 32;
  const pageUrl = window.location.href;
  const pageTitle =
    (market ? market.name : "") + " Amway Calculator - " + chart.name;
  const shareImg = "https://www.amwaycalculator.com/android-chrome-192x192.png";
  return (
    <>
      <Typography variant="h4" className={classes.root}>
        <span>{props.chart.name}</span>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={3} className={classes.root}>
          {ownerId && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<EditIcon />}
              onClick={() => setOpen(true)}
            >
              Edit
            </Button>
          )}
          <Button
            variant="contained"
            disabled={loading}
            color="secondary"
            size="large"
            startIcon={<SaveIcon />}
            onClick={onSave}
          >
            {ownerId === "" ? "Clone This Calculator" : "Save"}
          </Button>
        </Grid>
        <Grid item container xs={9} className={classes.root} justify="flex-end">
          <FacebookShareButton url={pageUrl} quote={pageTitle}>
            <FacebookIcon size={socialButtonSize} round />
          </FacebookShareButton>
          <TwitterShareButton url={pageUrl} title={pageTitle}>
            <TwitterIcon size={socialButtonSize} round />
          </TwitterShareButton>
          <PinterestShareButton url={pageUrl} media={shareImg}>
            <PinterestIcon size={socialButtonSize} round />
          </PinterestShareButton>
          <VKShareButton url={pageUrl} image={shareImg}>
            <VKIcon size={socialButtonSize} round />
          </VKShareButton>
          <RedditShareButton
            url={pageUrl}
            title={pageTitle}
            windowWidth={660}
            windowHeight={460}
          >
            <RedditIcon size={socialButtonSize} round />
          </RedditShareButton>
          <WhatsappShareButton url={pageUrl} title={pageTitle} separator=":: ">
            <WhatsappIcon size={socialButtonSize} round />
          </WhatsappShareButton>
        </Grid>
      </Grid>
      <ChartFormDialog open={open} onClose={() => setOpen(false)} {...props} />
    </>
  );
};
