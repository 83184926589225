import { Avatar, Box, Container, ListItemIcon, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { toast } from "react-toastify";
import * as store from "store2";
import { LSKEY_USER_ID, URL_PATH_CALC } from "../common/Constants";
import { ListItemLink } from "../common/ListItemLink";
import { ShowProgress } from "../common/ShowProgress";
import { Chart, useDeleteChartMutation, useFindChartsByOwnerIdQuery } from "../graphql/generated/graphql";
import { FabButton } from "./FabButton";
import { MarketList } from "./MarketList";

export const CalChartList: React.FC = () => {
  const { loading, data: allChts, refetch } = useFindChartsByOwnerIdQuery({
    variables: {
      ownerId: store.get(LSKEY_USER_ID)
    },
    skip: !store.get(LSKEY_USER_ID),
    fetchPolicy: "network-only"
  });
  const calCharts = allChts && (allChts.findChartsByOwnerId as Chart[]);
  const [deleteChart] = useDeleteChartMutation({
    onCompleted: d => {
      refetch();
      toast.success("Delete Calculator Successfully. ");
    }
  });
  return (
    <>
      <Container>
        <Box mt={12} mb={3}></Box>
        {loading && <ShowProgress />}
        <Box mb={10}>
          <MaterialTable
            title="My Amway Calculators"
            columns={[
              {
                title: "Name",
                field: "name",
                render: cht => (
                  <ListItemLink
                    primary={cht.name}
                    to={`${URL_PATH_CALC}/${cht.market ? cht.market.urlSlug : cht.marketId}/${cht.id}`}
                    icon={
                      <ListItemIcon>
                        <Avatar aria-label="recipe" src={cht.ibos!.iboList![0]!.avatar || ""} />
                      </ListItemIcon>
                    }
                  />
                ),
                headerStyle: {
                  minWidth: 400
                }
              },
              { title: "Market", field: "market.name", render: cht => cht.market && <Typography variant="h5"><span className={`flag-icon flag-icon-${cht.market.isoCode}`}></span></Typography>},
              {
                title: "Last Updated",
                field: "dateUpdated",
                type: "datetime"
              },
              {
                title: "# of Ibos",
                field: "ibos.iboList.length",
                type: "numeric"
              }
            ]}
            data={calCharts || []}
            actions={[
              {
                icon: "delete_outline",
                tooltip: "Delete",
                onClick: (event, rowData) => {
                  const cht = rowData as Chart;
                  if (window.confirm("Do you want to delete Calculator " + cht.name + " ?"))
                    deleteChart({ variables: { chartId: cht.id! } });
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              padding: "dense"
            }}
          />
        </Box>
        <MarketList />
      </Container>
      <FabButton />
    </>
  );
};
