import * as React from "react";
import { Container, Box } from "@material-ui/core";

interface Props {}

export const Terms: React.FC<Props> = () => {
  const site = "amwaycalculator";
  return (
    <Container>
      <Box mt={12} mb={3}></Box>
      <h4></h4>
      <div>
        <h1>Terms &amp; Conditions</h1>
        <h4>
          IMPORTANT! PLEASE READ THESE TERMS OF USE (THE "TERMS") CAREFULLY BEFORE USING {site}.com (THE "WEB SITES"),
          AS IT AFFECTS AND GOVERNS YOUR USE OF THESE WEB SITES AND YOUR LEGAL RIGHTS AND OBLIGATIONS.
        </h4>
        <p>
          Welcome to {site}.com, an free online tool to calculate Amway income.
          By using this Site you consent and agree to the terms and conditions contained in this Agreement.{" "}
        </p>
        <h3>PRIVACY</h3>
        <p>
          Please review our <a href="/privacy-policy">Privacy Policy</a>, which also governs your visit to
          {site}.com, to understand our practices.
        </p>
        <h3>EXTERNAL LINKS</h3>
        <p>
          The Site may provide links to web sites and access to content, products and services of third parties,
          including without limitation, {site}.com' affiliates and strategic partners and other entities with which our
          connection consists of only a hyperlink ("Other Sites"). {site}.com is not responsible for (a) the
          availability of, and content provided on, Other Sites, nor does inclusion of any link imply endorsement of the
          Other Site by {site}.com, or vice versa; (b) third party content accessible through the Site, including
          opinions, advice, or statements (and you understand that you bear all risks associated with the use of such
          content); (c) any loss or damage of any sort you may incur from dealing with any third party; or (d) your
          dealings with any third parties found on or through the Site, including your participation in promotions, the
          payment for and delivery of goods if any, and any terms, conditions, warranties, or representations associated
          with such dealings. Your participation in the third party site is governed by their terms and conditions.
        </p>
        <h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>
        <p>
          THIS SITE IS PROVIDED BY {site}.com ON AN "AS IS" AND "AS AVAILABLE" BASIS. {site}.com MAKES NO
          REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE
          INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS
          SITE IS AT YOUR SOLE RISK.
        </p>
        <p>
          TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, {site}.com DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
          {site}.com DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM {site}.com ARE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS. {site}.com WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF
          THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.
        </p>
        <p>
          CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
          DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT
          APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
        </p>
        <h3>INFORMATION ACCURACY</h3>
        <p>
          While {site}.com takes reasonable steps to present correct and accurate information, we cannot be responsible
          for the change of information at Amway offical sites. The {site}.com does not warrant that functions
          contained in {site}.com content will be uninterrupted or error free, that defects will be corrected, or that{" "}
          {site}.com or the server that makes it available are free of viruses or bugs. IN NO EVENT SHALL {site}.com OR
          ITS AFFILIATES BE LIABLE FOR ANY DAMAGES (WHETHER CONSEQUENTIAL, DIRECT, INCIDENTAL, INDIRECT, PUNITIVE,
          SPECIAL OR OTHERWISE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, A THIRD PARTY'S OFFERS , REGARDLESS OF
          WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT OR OTHER THEORIES OF LIABILITY, AND ALSO
          REGARDLESS OF WHETHER {site}.com WAS GIVEN ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE.
        </p>
        <h3>INDEMNIFICATION</h3>
        <p>
          You agree to indemnify and hold harmless {site}.com and its parents, sister companies, subsidiaries,
          affiliates, service providers, other End Users, distributors, licensors, officers, directors and employees
          from any claim or demand, including reasonable attorneys' fees, made by any third party arising out of or
          related to your violation of this Terms and Conditions, or your violation of any law, regulation or
          third-party right.
        </p>
        <h3>GENERAL</h3>
        <p>
          No delay or failure to take action under this Terms and Conditions shall constitute any waiver by
          {site}.com of any provision of this Terms and Conditions. If any provision of this Terms and Conditions is
          invalid or unenforceable under applicable law, it is, to that extent, deemed enforceable to the fullest extent
          possible (and severable in the event such provision is completely unenforceable) and the remaining provisions
          of this Terms and Conditions will continue in full force and effect. This Terms and Conditions will bind and
          inure to the benefit of {site}.com's permitted successors and assigns. Any claim under these Terms and
          Conditions must be brought within one (6) months after the cause of action arises. This Terms and Conditions
          is personal to you and may not be transferred, assigned or delegated to anyone. Any attempt by you to assign,
          transfer or delegate this Terms and Conditions shall be null and void. {site}.com may freely assign this Terms
          and Conditions without consent or notice. This Terms and Conditions (including all documents expressly
          incorporated herein by reference, including but not limited, to the relevant Usage Rules) constitutes the
          complete and exclusive agreement between {site}.com and you with respect to the subject matter hereof and
          supersedes all prior oral or written understandings, communications or agreements not specifically
          incorporated herein.
        </p>
        <p>
          {site}.com reserves the right, at its sole discretion, to change, add or remove portions of this Terms and
          Conditions, at any time. It is your responsibility to check this Terms and Conditions each time before using
          the Site. Your continued use of the Site following the posting of changes will mean that you accept and agree
          to the changes. As long as you comply with this Terms and Conditions and any such modifications,
          {site}.com grants you ("End User") a personal, non-exclusive, non-transferable, non-sublicensable, limited
          privilege to enter and use the Site.
        </p>
        <h3>ACKNOWLEDGEMENT</h3>
        <p>
          You acknowledge (a) that you have read and understood this Agreement; and (b) that this Agreement has the same
          force and effect as a signed agreement.
        </p>
        <p></p>
        <p>
          If there are any questions regarding this website, you may contact us by sending an email to: info |at|
          {site} (.) com
        </p>
      </div>
    </Container>
  );
};
