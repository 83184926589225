import * as React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
interface Props {}

export const ShowProgress: React.FC<Props> = () => {
  return (
    <div style={{ display: "flex" }}>
      <CircularProgress />
    </div>
  );
};
