import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
// import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    logo: {
      maxWidth: 50
    }
  })
);

export default function SearchAppBar() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="Home"
          onClick={() => history.push("/")}
        >
          <img src="/android-chrome-192x192.png" alt="logo" className={classes.logo} />
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          Amway Calculator
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
