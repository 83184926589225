import * as React from "react";
import { Container, Box } from "@material-ui/core";

interface Props {}

export const Privacy: React.FC<Props> = () => {
  const site = "amwaycalculator";
  return (
    <Container>
      <Box mt={12} mb={3}></Box>
      <h4></h4>
      <div>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy explains how {site}.com ("us", "our", and "we"), collects and uses information when you
          interact with us. If you do not agree to our practices, please do not register, create an account, or
          otherwise use our services.
        </p>
        <h3>Personal Information We Collect</h3>
        <Box>
          - <b>Contact Information</b> such as your name, email addresses,telephone numbers you provided to us.
        </Box>
        <Box>
          - <b>Transaction Information</b> about how you interact with {site}.com's merchants, business partners, and
          service providers.
        </Box>
        <h3>How {site}.com Collects Personal Information</h3>
        <Box>- Personal Information is collected when you register, subscribe, create an account.</Box>
        <Box>- Transaction Information is collected when you use our Services. For example, the comments you make.</Box>
        <Box>
          - We also use cookies and related technologies to collect information about your computer and your interaction
          with the Services and our business partner websites. You can read more in the Cookies section.
        </Box>
        <h3>How {site}.com Uses Personal Information</h3>
        We use the information to:
        <Box>- Operate and improve our Services</Box>
        <Box>- Provide advertisements to you on {site}.com</Box>
        <Box>- Provide other services which you consent</Box>
        <h3>Disclosure of Personal Information</h3>
        Your Personal Information may be shared as required or permitted by law as described below:
        <Box>
          - Merchants, only to the extent you have redeemed a deal / coupon offered by the merchant, or participated in
          an activity sponsored or offered through us on behalf of the merchant;
        </Box>
        <Box>
          - Successor-in-interest of {site}.com, such as a company that acquires {site}.com. In the event
          {site}.com goes through a business transition, such as a merger, acquisition by another company, or sale of
          all or a portion of its assets, your personally identifiable information will likely be among the assets
          transferred. You will be notified via email or by a notice on the {site}.com Web Site of any such change in
          ownership or control of your personal information.
        </Box>
        <Box>- Otherwise with your consent.</Box>
        <h3>Cookies</h3>
        <Box>
          - A cookie is a small amount of information that a web server sends to your browser that stores information
          about your account, your preferences, and your use of the Services.
        </Box>
        <Box>
          - When you use our service, cookies are used between your browser and servers operated by us, our business
          partners and services providers to coordinate and record the interactivity and fill your requests for services
          and information. These cookies may include means for tracking your Transaction Information with a Merchant and
          may include tracking technology from third-party affiliate-network operators like Commission Junction and
          Linkshare.
        </Box>
        <Box>
          - We also use third party services, such as Google Analytics, to help us understand how visitors interact with
          our website and to help improve our user experience.
        </Box>
        <h3>Management of Your Personal Information</h3>
        <Box>
          Cookies: You may manage how your browser handles cookies by adjusting its privacy and security settings. Refer
          your browser's help page to find out how to adjust. You can opt-out of being targeted by certain third party
          advertising companies online at http://optout.networkadvertising.org.
        </Box>
        <h3>Third party links</h3>
        <p>
          We include or offer web links to third party products or services on our website. These third party sites have
          separate and independent privacy policies. We therefore have no responsibility or liability for the content
          and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome
          any feedback about these sites.
        </p>
        <h3>California Online Privacy Protection Act Compliance</h3>
        <Box>
          - Because we value your privacy we have taken the necessary precautions to be in compliance with the
          California Online Privacy Protection Act. We therefore will not distribute your personal information to
          outside parties without your consent.
        </Box>
        <Box>
          - As part of the California Online Privacy Protection Act, all users of our site may make any changes to their
          information at anytime by logging into their control panel and going to the 'My Account' page.
        </Box>
        <h3>Rights Applicable to EU Users</h3>
        <p>
          Consent: We will rely on your consent to use (i) technical information such as cookie data and geolocation
          data; and (ii) your Personal Information for marketing purposes. You may withdraw your consent at any time by
          contacting us using the information at the end of this Privacy Policy or by following an unsubscribe link in
          any marketing communication you receive from us.
        </p>
        <p>If you are subject to EU law, you may ask us to:</p>
        <Box>- See what Personal Information we hold about you</Box>
        <Box>- Erase your Personal Information</Box>
        <Box>- Port your Personal Information</Box>
        <Box>- Restrict our use of your Personal Information</Box>
        <p>If you are not subject to EU law, these rights do not apply to you.</p>
        <h3>Children's Privacy</h3>
        <Box>
          {site}.com does not intend that any portion of the Services will be accessed or used by children under the age
          of eighteen, and such use is prohibited. The Services are designed and intended for adults. By using the
          Services, you represent that you are at least eighteen years old and understand that you must be at least
          eighteen years old in order to create an account and purchase the goods or services advertised through the
          Services. We will promptly delete information associated with any account for which we obtain actual knowledge
          that the registered user associated with the account is not at least eighteen years old.
        </Box>
        <p></p>
        <p>
          If there are any questions regarding this website, you may contact us by sending an email to: info |at|
          {site} (.) com
        </p>
      </div>
    </Container>
  );
};
