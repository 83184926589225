import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { ChartFormDialog } from "./ChartFormDialog";
interface Props {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "fixed",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    }
  })
);
export const FabButton: React.FC<Props> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Fab
        color="secondary"
        aria-label="add"
        className={classes.fab}
        
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </Fab>
      <ChartFormDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
