import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoreVert from "@material-ui/icons/MoreVert";
import * as React from "react";
import { OneIboProp } from "../CalcMain";
export const OneIboMatAction: React.FC<OneIboProp> = ({ ibo, handleAddIbo, handleDeleteIbo }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton aria-label="settings" onClick={handleClick} style={{padding: "12px 2px"}}>
        <MoreVert />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>Edit</MenuItem> */}
        <MenuItem
          onClick={() => {
            handleAddIbo(ibo.id);
            setAnchorEl(null);
          }}
          >
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Add a downline" />
        </MenuItem>
        <MenuItem
          disabled={!ibo.parentId}
          onClick={() => {
            handleDeleteIbo(ibo.id);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Delete this IBO" />
        </MenuItem>
      </Menu>
    </>
  );
};
