import * as React from "react";
import AdSense from "react-adsense";
import { isProduction } from "./Utils";

interface Props {
  slot: string;
  size?: string;
}

export const AdsWidget: React.FC<Props> = ({ slot, size = "300" }) => {
  return (
    <>
      {isProduction() ? (
        <AdSense.Google client="ca-pub-1036709600013498" slot={slot} format="auto" responsive="true" />
      ) : (
        <img alt="place hold it" src={`https://place-hold.it/${size}`} />
      )}
    </>
  );
};
