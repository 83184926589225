import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import AppBar from "./AppBar";
import { Footer } from "./Footer";
const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex"
  }
}));
export const DefaultLayout: React.FC<RouteProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Route {...rest}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar />
        {children}
      </div>
      <Footer />
    </Route>
  );
};
