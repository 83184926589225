import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as React from "react";
import { Market, useFindAllMarketsQuery } from "../graphql/generated/graphql";

interface Props {
  selectedMarket: (market: Market) => void;
  preSelected: number;
}

export const MarketAutoComplete: React.FC<Props> = ({ selectedMarket, preSelected }) => {
  const { data: mktData } = useFindAllMarketsQuery();
  const allMkts = mktData && (mktData.findAllMarkets as Market[]);
  return (
    <>
      {allMkts && (
        <Autocomplete
          id="market-select-demo"
          options={allMkts}
          autoHighlight
          getOptionLabel={option => option.name}
          renderOption={option => (
            <React.Fragment>
              <span className={`flag-icon flag-icon-${option.isoCode}`}></span>
              | {option.name}
            </React.Fragment>
          )}
          defaultValue={allMkts.find(m => m.id === preSelected)}
          // @ts-ignore
          onChange={(event, value) => selectedMarket(value)}
          renderInput={params => (
            <TextField margin="normal" {...params} label="Choose Amway Market" variant="outlined" />
          )}
        />
      )}
    </>
  );
};
