import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { URL_PATH_CALC } from "../common/Constants";
import { ListItemLink } from "../common/ListItemLink";
import { ShowProgress } from "../common/ShowProgress";
import { isProduction } from "../common/Utils";
import { Market, useFindAllMarketsQuery } from "../graphql/generated/graphql";
import { ChartFormDialog } from "./ChartFormDialog";
interface Props {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  largeAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#fff",
  },
}));

export const MarketList: React.FC<Props> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [marketId, setMarketId] = React.useState(-1);
  const { loading, data: mktData } = useFindAllMarketsQuery();
  const allMkts = mktData && (mktData.findAllMarkets as Market[]);
  const regionMap =
    allMkts &&
    allMkts.reduce((regM, mkt) => {
      if (!regM.get(mkt.region)) regM.set(mkt.region, []);
      regM.get(mkt.region)!.push(mkt);
      return regM;
      // ...regM,
      // [mkt.region]: [...(regM.get(mkt.region) || [])]
    }, new Map<string, Market[]>());
  const today = new Date().toISOString().slice(0, 10);
  return (
    <>
      {allMkts && (
        <>
          <Box mb={3}>
            <Typography variant="h4">Create a New Amway Income Calculator:</Typography>
          </Box>
          {loading && <ShowProgress />}
          {regionMap &&
            [...regionMap.keys()].sort().map((r) => (
              <React.Fragment key={r}>
                <Box my={3}>
                  <Typography variant="h5">{r}</Typography>
                </Box>
                <Grid container spacing={2}>
                  {regionMap.get(r) &&
                    regionMap
                      .get(r)!
                      //   .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((m) => (
                        <Grid item xs={12} sm={6} md={4} key={m.name}>
                          <Paper elevation={3}>
                            <List className={classes.root} key={m.name}>
                              <ListItemLink
                                to={`${URL_PATH_CALC}/${m!.urlSlug}`}
                                primary={m.name + " Amway Calculator"}
                                icon={
                                  <Box mr={2}>
                                    <Typography variant="h4"><span className={`flag-icon flag-icon-${m.isoCode}`}></span></Typography>
                                  </Box>
                                }
                              />
                            </List>
                          </Paper>
                        </Grid>
                      ))}
                </Grid>
              </React.Fragment>
            ))}
          <ChartFormDialog open={open} marketId={marketId} onClose={() => setOpen(false)} />
          {!isProduction() && (
            <Box m={3}>
              {`<url><loc>https://www.amwaycalculator.com/amway-pv-chart-amway-income-calculator/united-states-usa/</loc>
              <xhtml:link rel="alternate" hreflang="x-default" href="https://www.amwaycalculator.com/"/>`}
              {allMkts.map(
                (m) =>
                  `<xhtml:link rel="alternate" hreflang="en-${
                    m!.isoCode
                  }" href="https://www.amwaycalculator.com${URL_PATH_CALC}/${m!.urlSlug}/"/>`
              )}
              {"</url>"}
            </Box>
          )}
        </>
      )}
    </>
  );
};
