import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { Market } from "../graphql/generated/graphql";
interface Props {
  market: Market;
}

export const CalcScheduleTable: React.FC<Props> = ({ market }) => {
  return (
    <Box m={2}>
      <Typography variant="h6">Amway {market!.name} <span className={`flag-icon flag-icon-${market.isoCode}`}></span> Performance Bonus Schedule (Amway PV Chart)</Typography>
      <Box my={2}>
        The average PV/BV ratio for {market!.name} Amway market is 1 : {market!.bvPvRatio}
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>PV (Point Value)</TableCell>
              <TableCell>BV (Business Volumn)</TableCell>
              <TableCell>Performance Bonus Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {market!.schedules!.perfSchedules!.map(row => (
              <TableRow key={row!.pv!}>
                <TableCell component="th" scope="row">
                  {row!.pv}
                </TableCell>
                <TableCell align="right">
                  {market.currency}{" "}
                  {Math.round(row!.pv! * market!.bvPvRatio)}
                </TableCell>
                <TableCell align="right">{row!.rate! * 100}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
