import { createStyles, Divider, Drawer, IconButton, ListItemIcon, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import List from "@material-ui/core/List";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import React from "react";
import { ListItemLink } from "../common/ListItemLink";
import { Market } from "../graphql/generated/graphql";
import { CalcScheduleTable } from "./CalcScheduleTable";

interface Props {}

const drawerWidth = 380;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerOpen: {
      width: drawerWidth,
      marginTop: theme.spacing(8),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      marginTop: theme.spacing(8),
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1
      }
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    }
  })
);
interface Props {
  market: Market;
  open: boolean;
  setOpen: (o: boolean) => void;
}

export const SideBar: React.FC<Props> = ({ market, open, setOpen }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:800px)");
  React.useEffect(() => setOpen(matches), [matches]);
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => setOpen(!open)} edge="start">
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <List>
        <ListItemLink
          to="/"
          primary="Back To List"
          icon={
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
          }
        />
      </List>
      <Divider />
      {open && market && <CalcScheduleTable market={market} />}
    </Drawer>
  );
};
