import React from "react";
import { OneIboProp } from "./CalcMain";
import { OneIboMat } from "./ibocard/OneIboMat";
import "./style.scss";

export const IboTree: React.FC<OneIboProp> = oneIboProp => {
  return (
    <div className="node">
      <OneIboMat {...oneIboProp} />
      {oneIboProp.ibo.downlines && (
        <ul>
          {oneIboProp.ibo.downlines.map(x => (
            <li key={x.id!}>
              <IboTree {...oneIboProp} ibo={x} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
