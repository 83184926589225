import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Calculator from "../calculator/CalcMain";
import { CalChartList } from "../chart/CalChartList";
import { URL_PATH_CALC } from "../common/Constants";
import { DefaultLayout } from "./DefaultLayout";
import { Privacy } from "../page/privacy";
import { Terms } from "../page/Terms";

export const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/" exact component={Home} /> */}
        <DefaultLayout path="/" exact>
          <CalChartList />
        </DefaultLayout>
        {/* redirect legacy url */}
        <Route path={`${URL_PATH_CALC}/`} exact>
          <Redirect to="/" />
        </Route>
        <DefaultLayout path={`${URL_PATH_CALC}/:marketSlug/:calChartId?`} exact>
          <Calculator />
        </DefaultLayout>
        <DefaultLayout path="/privacy-policy" exact>
          <Privacy />
        </DefaultLayout>
        <DefaultLayout path="/terms-and-conditions" exact>
          <Terms />
        </DefaultLayout>
        <Route path="/" render={() => <Redirect to={`/`} />} />
      </Switch>
    </BrowserRouter>
  );
};
