import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Popper from "@material-ui/core/Popper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import React, { useState } from "react";
import { getRandomInt } from "../../common/Utils";
import { OneIboProp } from "../CalcMain";
import { OneIboMatAction } from "./OneIboMatAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: 130,
      textAlign: "left",
    },
    cardHeaderAvatar: {
      // backgroundColor: red[500]
      marginRight: 5,
    },
    textField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      // width: 200
    },
    pullRight: {
      marginLeft: "auto",
    },
    nameValue: {
      ...theme.typography.body2,
      fontSize: "0.75rem",
    },
    gridList: {
      width: 200,
      height: 200,
    },
  })
);

export const OneIboMat: React.FC<OneIboProp> = (oneIboProp) => {
  const randomAvatar = () =>
    `https://randomuser.me/api/portraits/${["women", "men"][getRandomInt(0, 1)]}/${getRandomInt(0, 99)}.jpg`;
  const ibo = oneIboProp.ibo;
  const [iboEditable, setIboEditable] = useState({
    name: ibo.name,
    selfBv: ibo.selfBv,
    avatar: ibo.avatar,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const onSelectAvatar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    oneIboProp.handleIboInfoChange(ibo.id, {
      selfBv: ibo.selfBv,
      name: ibo.name,
      avatar: (e.target as HTMLImageElement).src,
    });
    setAnchorEl(null);
  };
  return (
    <Card className={classes.card} elevation={3}>
      <CardContent style={{ padding: 10 }}>
        <CardHeader
          style={{ padding: 0 }}
          classes={{ avatar: classes.cardHeaderAvatar }}
          avatar={
            <Avatar
              aria-label="recipe"
              src={ibo.avatar || ""}
              onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
            />
          }
          action={<OneIboMatAction {...oneIboProp} />}
          title={
            <TextField
              className={classes.textField}
              value={ibo.name}
              inputProps={{ style: { fontSize: "0.85rem" } }}
              onChange={(e) => {
                setIboEditable({ ...iboEditable, name: e.target.value });
                oneIboProp.handleIboInfoChange(ibo.id, {
                  selfBv: ibo.selfBv,
                  name: e.target.value,
                  avatar: ibo.avatar,
                });
              }}
            />
          }
        />
        <Popper open={!!anchorEl} anchorEl={anchorEl} role={undefined} transition placement="right-start">
          <Card>
            <CardContent>
              <GridList cellHeight={45} className={classes.gridList} cols={4}>
                {[...Array(16).keys()].map((i) => (
                  <GridListTile key={i + `avail`}>
                    <Avatar src={randomAvatar()} onClick={onSelectAvatar} />
                  </GridListTile>
                ))}
              </GridList>
            </CardContent>
            <CardActions>
              <Button
                style={{ marginLeft: "auto" }}
                size="small"
                startIcon={<CancelPresentationIcon />}
                onClick={() => setAnchorEl(null)}
              >
                Close
              </Button>
            </CardActions>
          </Card>
        </Popper>
        <Grid container alignItems="center" className={classes.nameValue}>
          <Grid item xs>
            Self BV:
          </Grid>
          <Grid item xs>
            <TextField
              type="number"
              className={classes.textField}
              value={ibo.selfBv}
              inputProps={{
                step: 100,
                style: { textAlign: "right", fontSize: "0.85rem" },
              }}
              onChange={(e) => {
                setIboEditable({ ...iboEditable, selfBv: +e.target.value });
                if (!isNaN(+e.target.value))
                  oneIboProp.handleIboInfoChange(ibo.id, {
                    selfBv: Math.max(+e.target.value, 0),
                    name: ibo.name,
                    avatar: ibo.avatar,
                  });
              }}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.nameValue}>
          <Grid item xs>
            Bonus Level:
          </Grid>
          <Grid item>{(ibo.bonusRate || 0) * 100}%</Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.nameValue}>
          <Grid item xs>
            Total PV:
          </Grid>
          <Grid item>{ibo.totalPv || 0}</Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.nameValue}>
          <Grid item xs>
            Total BV:
          </Grid>
          <Grid item>{ibo.totalBv || 0}</Grid>
        </Grid>
        <Grid container alignItems="center" className={classes.nameValue}>
          <Grid item xs>
            Bonus:
          </Grid>
          <Grid item>${ibo.bonus || 0}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
