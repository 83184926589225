import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, Observable } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import nanoid from "nanoid";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "store2";
import App from "./App";
import { LSKEY_USER_ID } from "./common/Constants";
import { getAccessToken } from "./common/SecurityToken";
import { isProduction } from "./common/Utils";

if (!store.get(LSKEY_USER_ID)) store.set(LSKEY_USER_ID, nanoid(16));
toast.configure();

if (isProduction()) {
  const tagManagerArgs = {
    gtmId: "GTM-MT69VC"
  };
  TagManager.initialize(tagManagerArgs);
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

// const OmitTypenameLink = new ApolloLink((operation, forward) => {
//   if (operation.variables) {
//     operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
//   }
//   return forward(operation);
// });

// function omitTypename(key: string, value: string) {
//   return key === "__typename" ? undefined : value;
// }

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) toast.error(graphQLErrors.map(g => g.message).join("; "));
      if (networkError) toast.error(networkError.message);
    }),
    // OmitTypenameLink,
    requestLink,
    new HttpLink({
      uri: isProduction() ? "/graphql" : "http://localhost:8080/graphql"
    })
  ]),
  cache: new InMemoryCache({
    addTypename: false
  })
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
    <ToastContainer position={"bottom-left"}/>
  </ApolloProvider>,
  document.getElementById("root")
);
