import { Box, createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import nanoid from "nanoid";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import store from "store2";
import { AdsWidget } from "../common/AdsWidget";
import { LSKEY_USER_ID } from "../common/Constants";
import { ShowProgress } from "../common/ShowProgress";
import { IboEx, initialData } from "../common/Utils";
import { Chart, Ibo, Market, useFindAllMarketsQuery, useFindChartByIdQuery } from "../graphql/generated/graphql";
import { CalcHeader } from "./CalcHeader";
import { IboService } from "./IboService";
import { IboTree } from "./IboTree";
import { SideBar } from "./SideBar";

const drawerWidth = 380;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      // flexBasis: "100%",
      padding: theme.spacing(2),
      // marginLeft: 60,
    },
    drawerOpen: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: drawerWidth,
      // flexBasis: "100%",
    },
    drawerClose: {
      marginLeft: theme.spacing(7) + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar
  })
);

export interface OneIboProp {
  ibo: IboEx;
  handleIboInfoChange: (iboId: string, iboInfo: IboEditable) => void;
  handleAddIbo: (addToIboId: string) => void;
  handleDeleteIbo: (deleteIboId: string) => void;
}
export interface IboEditable {
  selfBv: number;
  name: string;
  avatar: string | null | undefined;
}

const Calculator: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { calChartId, marketSlug } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [iboList, setIboList] = useState<Ibo[]>(initialData);
  const { loading, data: chartData, refetch } = useFindChartByIdQuery({
    variables: {
      chartId: calChartId!
    },
    skip: !calChartId,
    fetchPolicy: "network-only",
    onCompleted: d => {
      if (d && d.findChartById) {
        setIboList(d.findChartById.ibos.iboList as Ibo[]);
      }
    }
  });
  const { data: mktData } = useFindAllMarketsQuery();
  const allMkts = mktData && (mktData.findAllMarkets as Market[]);
  const market = allMkts && allMkts.find(m => m.urlSlug === marketSlug);
  if (loading || !market) return <ShowProgress />;
  const savedChart = chartData && (chartData.findChartById as Chart);

  const chart: Chart =
    calChartId && savedChart
      ? { ...savedChart }
      : {
          id: nanoid(16),
          name: "New Amway " + market.name + " Calculator",
          ownerId: store.get(LSKEY_USER_ID),
          marketId: market!.id,
          ibos: { iboList: iboList ? iboList : initialData }
        };

  const handleIboInfoChange: (iboId: string, iboInfo: IboEditable) => void = (iboId, iboInfo) => {
    setIboList(IboService.updateIboInfo(iboList!, iboId, iboInfo));
  };
  const handleAddIbo: (addToIboId: string) => void = addToIboId => {
    console.log("add to ibo", addToIboId);
    setIboList(IboService.addIbo(iboList!, addToIboId));
  };
  const handleDeleteIbo: (deleteIboId: string) => void = deleteIboId => {
    setIboList(IboService.deleteIbo(iboList!, deleteIboId));
  };

  let ibo = IboService.populateTree(iboList!);
  IboService.calcTotalBv(ibo, market!);
  IboService.calcBonus(ibo);
  return (
    <>
      {chart && (
        <>
          {market && <SideBar market={market} open={drawerOpen} setOpen={setDrawerOpen} />}
          <main
            className={clsx(classes.content, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen
            })}
          >
            {/* <div className={classes.toolbar} /> */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={10}>
                <Box style={{ overflowX: "scroll" }}>
                  <Button color="primary" onClick={() => history.push("/")}>
                    <ArrowBackIcon /> Back to List
                  </Button>
                  <CalcHeader chart={chart} iboList={iboList} refetchChart={refetch} />
                  <Box mx={2} textAlign="center" style={{ overflow: "auto" }}>
                    <ul className="rootCard">
                      <li className="rootCard">
                        <IboTree
                          ibo={ibo!}
                          handleIboInfoChange={handleIboInfoChange}
                          handleAddIbo={handleAddIbo}
                          handleDeleteIbo={handleDeleteIbo}
                        />
                      </li>
                    </ul>
                    <br />
                  </Box>
                  <Box my={3} textAlign="center" style={{minWidth: 350}}>
                    <AdsWidget slot="6650844479" size="720x90" />
                  </Box>
                  <Box>
                    <p>
                      Please input all the Business Volume (BV) in each node in the following chart. With the
                      calculator, you will be able to:
                    </p>
                    - Calculate the Bonus Level, Total PV, Total BV and Bonus for each partner in the chart.
                    <br />
                    - See the whole picture of your Amway team.
                    <br />
                    - Demonstrate the Amway opportunities to your potential new partners.
                    <br />
                    - Refer the list of PV, BV and Bonus Rate(Scale) quickly.
                    <br />
                    - Estimate your previous month Amway income with the calculation result.
                    <br />
                    - etc.
                    <br />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={12} lg={2}>
                <Hidden mdDown>
                  <AdsWidget slot="7979226207" size="300x600" />
                </Hidden>
              </Grid>
            </Grid>
          </main>
        </>
      )}
    </>
  );
};

export default Calculator;
