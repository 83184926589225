import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import store from "store2";
import { LSKEY_USER_ID } from "./common/Constants";
import { getAccessToken, setAccessToken } from "./common/SecurityToken";
import { isProduction } from "./common/Utils";
import { Routes } from "./layout/Routes";
// use default theme
// const theme = createMuiTheme();

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    //     primary: green,
    // secondary: orange,
    primary: {
      main: "#4CAF50",
      contrastText: "#fff"
    },
    secondary: {
      main: "#FF8A65",
      contrastText: "#fff"
    }
  }
});
const App: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!getAccessToken()) {
      const userId = store.get(LSKEY_USER_ID) as string;
      let formData = new FormData();
      formData.append("username", userId);
      formData.append("password", userId);
      fetch(isProduction() ? "/login" : "http://localhost:8080/login", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({ username: userId, password: userId })
      }).then(async x => {
        const { access_token } = await x.json();
        setAccessToken(access_token);
        setLoading(false);
      });
    }
  }, []);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Routes />
    </MuiThemeProvider>
  );
};

export default App;
