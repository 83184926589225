import nanoid from "nanoid";
import { Ibo } from "../graphql/generated/graphql";

export type IboEx = Ibo & {
  bonusRate?: number;
  totalPv?: number;
  totalBv?: number | null;
  bonus?: number;
  downlines?: IboEx[];
};

const rootId = nanoid(16);
export const initialData: Ibo[] = [
  { id: rootId, name: "You", selfBv: 300 },
  { id: nanoid(16), name: "Mary", selfBv: 700, parentId: rootId },
  { id: nanoid(16), name: "Charlie", selfBv: 5000, parentId: rootId }
];

export const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export const isProduction = () => process.env.NODE_ENV === "production";
