import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import { Link as RouteLink } from "react-router-dom";
import * as React from "react";
import { TwitterIcon } from "react-share";

interface Props {}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2, 2),
    marginTop: "30px",
    position: "unset",
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[800],
  },
}));
export const Footer: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Box display="flex" justifyContent="center">
        <Typography variant="body1" color="textSecondary">
          {"Copyright © "}
          <Link color="inherit" href="https://www.amwaycalculator.com/">
            AmwayCalculator.com
          </Link>{" "}
          {new Date().getFullYear()}
          {" | "}
          <Link color="inherit" href="https://twitter.com/AmwayCalculator">
            <TwitterIcon size={24}/>
          </Link>
          {" | "}
          <Link color="inherit" href="/privacy-policy">
            Privacy Policy
          </Link>
          {" | "}
          <Link color="inherit" href="/terms-and-conditions">
            Terms &amp; Conditions
          </Link>
        </Typography>
      </Box>
    </footer>
  );
};
