import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import nanoid from "nanoid";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import store from "store2";
import { LSKEY_USER_ID, URL_PATH_CALC } from "../common/Constants";
import { initialData } from "../common/Utils";
import { Chart, Ibo, useSaveChartMutation } from "../graphql/generated/graphql";
import { MarketAutoComplete } from "./MarketAutoComplete";

interface Prop {
  open: boolean;
  onClose: () => void;
  chart?: Chart;
  iboList?: Ibo[];
  marketId?: number;
  refetchChart?: (variables?: any) => void;
}

export const ChartFormDialog: React.FC<Prop> = ({ open: isopen, onClose, chart, iboList, marketId, refetchChart }) => {
  const history = useHistory();
  const { calChartId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [createForm, setCreateForm] = React.useState({ name: "", marketId: -1 });
  React.useEffect(() => {
    setOpen(isopen);
    setCreateForm({ name: chart ? chart.name : "", marketId: marketId || (chart ? chart.marketId : -1) });
  }, [isopen, chart, marketId]);
  const [saveChart] = useSaveChartMutation({
    onCompleted: d => {
      if (calChartId && refetchChart) refetchChart({ chartId: d.saveChart!.id! });
      else history.replace(`${URL_PATH_CALC}/${d.saveChart!.market!.urlSlug}/${d.saveChart!.id}`);
      toast.success("Save Calculator Successfully. ");
    }
  });
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const onSave = () => {
    handleClose();
    saveChart({
      variables: {
        chart: {
          id: chart ? chart.id : nanoid(16),
          ownerId: store.get(LSKEY_USER_ID),
          ibos: { iboList: iboList ? iboList : initialData },
          ...createForm
        },
        isCreate: !chart || !chart.dateCreated
      }
    });
  };
  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{(chart ? "Edit" : "Create A New") + " Amway Calculator"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            label="Calculator Name"
            type="text"
            fullWidth
            variant="outlined"
            margin="normal"
            value={createForm.name}
            onChange={e => setCreateForm({ ...createForm, name: e.target.value })}
          />
          <MarketAutoComplete
            preSelected={marketId || (chart ? chart.marketId : -1)}
            selectedMarket={m => setCreateForm({ ...createForm, marketId: m ? m.id : -1 })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSave} color="primary" disabled={!createForm.name || createForm.marketId < 1}>
            {chart ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
