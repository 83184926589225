import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Instant: any;
  Long: any;
  UNREPRESENTABLE: any;
};

export type Chart = {
   __typename?: 'Chart';
  dateCreated?: Maybe<Scalars['Instant']>;
  dateUpdated?: Maybe<Scalars['Instant']>;
  description?: Maybe<Scalars['String']>;
  ibos: Ibos;
  id?: Maybe<Scalars['String']>;
  market?: Maybe<Market>;
  marketId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
};

export type ChartInput = {
  dateCreated?: Maybe<Scalars['Instant']>;
  dateUpdated?: Maybe<Scalars['Instant']>;
  description?: Maybe<Scalars['String']>;
  ibos: IbosInput;
  id?: Maybe<Scalars['String']>;
  marketId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
};

export type Ibo = {
   __typename?: 'Ibo';
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  selfBv: Scalars['Int'];
};

export type IboInput = {
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  selfBv: Scalars['Int'];
};

export type Ibos = {
   __typename?: 'Ibos';
  iboList?: Maybe<Array<Maybe<Ibo>>>;
};

export type IbosInput = {
  iboList?: Maybe<Array<Maybe<IboInput>>>;
};



export type Market = {
   __typename?: 'Market';
  bvPvRatio: Scalars['Float'];
  currency: Scalars['String'];
  dateUpdated?: Maybe<Scalars['Instant']>;
  id?: Maybe<Scalars['Long']>;
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
  schedules: PerformanceSchedules;
  urlSlug: Scalars['String'];
};

export type MarketInput = {
  bvPvRatio: Scalars['Float'];
  currency: Scalars['String'];
  dateUpdated?: Maybe<Scalars['Instant']>;
  id?: Maybe<Scalars['Long']>;
  isoCode: Scalars['String'];
  name: Scalars['String'];
  region: Scalars['String'];
  schedules: PerformanceSchedulesInput;
  urlSlug: Scalars['String'];
};

export type Mutation = {
   __typename?: 'Mutation';
  deleteChart: Scalars['Boolean'];
  saveMarket?: Maybe<Market>;
  saveChart?: Maybe<Chart>;
  updateMarket?: Maybe<Market>;
};


export type MutationDeleteChartArgs = {
  chartId?: Maybe<Scalars['String']>;
};


export type MutationSaveMarketArgs = {
  market?: Maybe<MarketInput>;
};


export type MutationSaveChartArgs = {
  isCreate: Scalars['Boolean'];
  chart?: Maybe<ChartInput>;
};


export type MutationUpdateMarketArgs = {
  market?: Maybe<MarketInput>;
};

export type PerformanceSchedule = {
   __typename?: 'PerformanceSchedule';
  pv?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
};

export type PerformanceScheduleInput = {
  pv?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Float']>;
};

export type PerformanceSchedules = {
   __typename?: 'PerformanceSchedules';
  perfSchedules?: Maybe<Array<Maybe<PerformanceSchedule>>>;
};

export type PerformanceSchedulesInput = {
  perfSchedules?: Maybe<Array<Maybe<PerformanceScheduleInput>>>;
};

export type Query = {
   __typename?: 'Query';
  findChartById?: Maybe<Chart>;
  findAllMarkets?: Maybe<Array<Maybe<Market>>>;
  findChartsByOwnerId?: Maybe<Array<Maybe<Chart>>>;
};


export type QueryFindChartByIdArgs = {
  chartId?: Maybe<Scalars['String']>;
};


export type QueryFindChartsByOwnerIdArgs = {
  ownerId?: Maybe<Scalars['String']>;
};


export type FindChartsByOwnerIdQueryVariables = {
  ownerId: Scalars['String'];
};


export type FindChartsByOwnerIdQuery = (
  { __typename?: 'Query' }
  & { findChartsByOwnerId?: Maybe<Array<Maybe<(
    { __typename?: 'Chart' }
    & ChartFragment
  )>>> }
);

export type FindChartByIdQueryVariables = {
  chartId: Scalars['String'];
};


export type FindChartByIdQuery = (
  { __typename?: 'Query' }
  & { findChartById?: Maybe<(
    { __typename?: 'Chart' }
    & ChartFragment
  )> }
);

export type DeleteChartMutationVariables = {
  chartId: Scalars['String'];
};


export type DeleteChartMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteChart'>
);

export type SaveChartMutationVariables = {
  chart: ChartInput;
  isCreate: Scalars['Boolean'];
};


export type SaveChartMutation = (
  { __typename?: 'Mutation' }
  & { saveChart?: Maybe<(
    { __typename?: 'Chart' }
    & ChartFragment
  )> }
);

export type ChartFragment = (
  { __typename?: 'Chart' }
  & Pick<Chart, 'id' | 'name' | 'marketId' | 'ownerId' | 'dateCreated' | 'dateUpdated'>
  & { market?: Maybe<(
    { __typename?: 'Market' }
    & MarketFragment
  )>, ibos: (
    { __typename?: 'Ibos' }
    & { iboList?: Maybe<Array<Maybe<(
      { __typename?: 'Ibo' }
      & Pick<Ibo, 'id' | 'name' | 'avatar' | 'parentId' | 'selfBv'>
    )>>> }
  ) }
);

export type FindAllMarketsQueryVariables = {};


export type FindAllMarketsQuery = (
  { __typename?: 'Query' }
  & { findAllMarkets?: Maybe<Array<Maybe<(
    { __typename?: 'Market' }
    & MarketFragment
  )>>> }
);

export type SaveMarketMutationVariables = {
  market: MarketInput;
};


export type SaveMarketMutation = (
  { __typename?: 'Mutation' }
  & { saveMarket?: Maybe<(
    { __typename?: 'Market' }
    & Pick<Market, 'name'>
  )> }
);

export type UpdateMarketMutationVariables = {
  market: MarketInput;
};


export type UpdateMarketMutation = (
  { __typename?: 'Mutation' }
  & { updateMarket?: Maybe<(
    { __typename?: 'Market' }
    & Pick<Market, 'name'>
  )> }
);

export type MarketFragment = (
  { __typename?: 'Market' }
  & Pick<Market, 'id' | 'name' | 'region' | 'urlSlug' | 'isoCode' | 'currency' | 'bvPvRatio' | 'dateUpdated'>
  & { schedules: (
    { __typename?: 'PerformanceSchedules' }
    & { perfSchedules?: Maybe<Array<Maybe<(
      { __typename?: 'PerformanceSchedule' }
      & Pick<PerformanceSchedule, 'pv' | 'rate'>
    )>>> }
  ) }
);

export const MarketFragmentDoc = gql`
    fragment market on Market {
  id
  name
  region
  urlSlug
  isoCode
  currency
  bvPvRatio
  dateUpdated
  schedules {
    perfSchedules {
      pv
      rate
    }
  }
}
    `;
export const ChartFragmentDoc = gql`
    fragment chart on Chart {
  id
  name
  marketId
  ownerId
  dateCreated
  dateUpdated
  market {
    ...market
  }
  ibos {
    iboList {
      id
      name
      avatar
      parentId
      selfBv
    }
  }
}
    ${MarketFragmentDoc}`;
export const FindChartsByOwnerIdDocument = gql`
    query FindChartsByOwnerId($ownerId: String!) {
  findChartsByOwnerId(ownerId: $ownerId) {
    ...chart
  }
}
    ${ChartFragmentDoc}`;

/**
 * __useFindChartsByOwnerIdQuery__
 *
 * To run a query within a React component, call `useFindChartsByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChartsByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChartsByOwnerIdQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useFindChartsByOwnerIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindChartsByOwnerIdQuery, FindChartsByOwnerIdQueryVariables>) {
        return ApolloReactHooks.useQuery<FindChartsByOwnerIdQuery, FindChartsByOwnerIdQueryVariables>(FindChartsByOwnerIdDocument, baseOptions);
      }
export function useFindChartsByOwnerIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindChartsByOwnerIdQuery, FindChartsByOwnerIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindChartsByOwnerIdQuery, FindChartsByOwnerIdQueryVariables>(FindChartsByOwnerIdDocument, baseOptions);
        }
export type FindChartsByOwnerIdQueryHookResult = ReturnType<typeof useFindChartsByOwnerIdQuery>;
export type FindChartsByOwnerIdLazyQueryHookResult = ReturnType<typeof useFindChartsByOwnerIdLazyQuery>;
export type FindChartsByOwnerIdQueryResult = ApolloReactCommon.QueryResult<FindChartsByOwnerIdQuery, FindChartsByOwnerIdQueryVariables>;
export const FindChartByIdDocument = gql`
    query FindChartById($chartId: String!) {
  findChartById(chartId: $chartId) {
    ...chart
  }
}
    ${ChartFragmentDoc}`;

/**
 * __useFindChartByIdQuery__
 *
 * To run a query within a React component, call `useFindChartByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChartByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChartByIdQuery({
 *   variables: {
 *      chartId: // value for 'chartId'
 *   },
 * });
 */
export function useFindChartByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindChartByIdQuery, FindChartByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<FindChartByIdQuery, FindChartByIdQueryVariables>(FindChartByIdDocument, baseOptions);
      }
export function useFindChartByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindChartByIdQuery, FindChartByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindChartByIdQuery, FindChartByIdQueryVariables>(FindChartByIdDocument, baseOptions);
        }
export type FindChartByIdQueryHookResult = ReturnType<typeof useFindChartByIdQuery>;
export type FindChartByIdLazyQueryHookResult = ReturnType<typeof useFindChartByIdLazyQuery>;
export type FindChartByIdQueryResult = ApolloReactCommon.QueryResult<FindChartByIdQuery, FindChartByIdQueryVariables>;
export const DeleteChartDocument = gql`
    mutation DeleteChart($chartId: String!) {
  deleteChart(chartId: $chartId)
}
    `;
export type DeleteChartMutationFn = ApolloReactCommon.MutationFunction<DeleteChartMutation, DeleteChartMutationVariables>;

/**
 * __useDeleteChartMutation__
 *
 * To run a mutation, you first call `useDeleteChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChartMutation, { data, loading, error }] = useDeleteChartMutation({
 *   variables: {
 *      chartId: // value for 'chartId'
 *   },
 * });
 */
export function useDeleteChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteChartMutation, DeleteChartMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteChartMutation, DeleteChartMutationVariables>(DeleteChartDocument, baseOptions);
      }
export type DeleteChartMutationHookResult = ReturnType<typeof useDeleteChartMutation>;
export type DeleteChartMutationResult = ApolloReactCommon.MutationResult<DeleteChartMutation>;
export type DeleteChartMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteChartMutation, DeleteChartMutationVariables>;
export const SaveChartDocument = gql`
    mutation SaveChart($chart: ChartInput!, $isCreate: Boolean!) {
  saveChart(chart: $chart, isCreate: $isCreate) {
    ...chart
  }
}
    ${ChartFragmentDoc}`;
export type SaveChartMutationFn = ApolloReactCommon.MutationFunction<SaveChartMutation, SaveChartMutationVariables>;

/**
 * __useSaveChartMutation__
 *
 * To run a mutation, you first call `useSaveChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveChartMutation, { data, loading, error }] = useSaveChartMutation({
 *   variables: {
 *      chart: // value for 'chart'
 *      isCreate: // value for 'isCreate'
 *   },
 * });
 */
export function useSaveChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveChartMutation, SaveChartMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveChartMutation, SaveChartMutationVariables>(SaveChartDocument, baseOptions);
      }
export type SaveChartMutationHookResult = ReturnType<typeof useSaveChartMutation>;
export type SaveChartMutationResult = ApolloReactCommon.MutationResult<SaveChartMutation>;
export type SaveChartMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveChartMutation, SaveChartMutationVariables>;
export const FindAllMarketsDocument = gql`
    query FindAllMarkets {
  findAllMarkets {
    ...market
  }
}
    ${MarketFragmentDoc}`;

/**
 * __useFindAllMarketsQuery__
 *
 * To run a query within a React component, call `useFindAllMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllMarketsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindAllMarketsQuery, FindAllMarketsQueryVariables>) {
        return ApolloReactHooks.useQuery<FindAllMarketsQuery, FindAllMarketsQueryVariables>(FindAllMarketsDocument, baseOptions);
      }
export function useFindAllMarketsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindAllMarketsQuery, FindAllMarketsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindAllMarketsQuery, FindAllMarketsQueryVariables>(FindAllMarketsDocument, baseOptions);
        }
export type FindAllMarketsQueryHookResult = ReturnType<typeof useFindAllMarketsQuery>;
export type FindAllMarketsLazyQueryHookResult = ReturnType<typeof useFindAllMarketsLazyQuery>;
export type FindAllMarketsQueryResult = ApolloReactCommon.QueryResult<FindAllMarketsQuery, FindAllMarketsQueryVariables>;
export const SaveMarketDocument = gql`
    mutation SaveMarket($market: MarketInput!) {
  saveMarket(market: $market) {
    name
  }
}
    `;
export type SaveMarketMutationFn = ApolloReactCommon.MutationFunction<SaveMarketMutation, SaveMarketMutationVariables>;

/**
 * __useSaveMarketMutation__
 *
 * To run a mutation, you first call `useSaveMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMarketMutation, { data, loading, error }] = useSaveMarketMutation({
 *   variables: {
 *      market: // value for 'market'
 *   },
 * });
 */
export function useSaveMarketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveMarketMutation, SaveMarketMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveMarketMutation, SaveMarketMutationVariables>(SaveMarketDocument, baseOptions);
      }
export type SaveMarketMutationHookResult = ReturnType<typeof useSaveMarketMutation>;
export type SaveMarketMutationResult = ApolloReactCommon.MutationResult<SaveMarketMutation>;
export type SaveMarketMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveMarketMutation, SaveMarketMutationVariables>;
export const UpdateMarketDocument = gql`
    mutation UpdateMarket($market: MarketInput!) {
  updateMarket(market: $market) {
    name
  }
}
    `;
export type UpdateMarketMutationFn = ApolloReactCommon.MutationFunction<UpdateMarketMutation, UpdateMarketMutationVariables>;

/**
 * __useUpdateMarketMutation__
 *
 * To run a mutation, you first call `useUpdateMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarketMutation, { data, loading, error }] = useUpdateMarketMutation({
 *   variables: {
 *      market: // value for 'market'
 *   },
 * });
 */
export function useUpdateMarketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMarketMutation, UpdateMarketMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMarketMutation, UpdateMarketMutationVariables>(UpdateMarketDocument, baseOptions);
      }
export type UpdateMarketMutationHookResult = ReturnType<typeof useUpdateMarketMutation>;
export type UpdateMarketMutationResult = ApolloReactCommon.MutationResult<UpdateMarketMutation>;
export type UpdateMarketMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMarketMutation, UpdateMarketMutationVariables>;