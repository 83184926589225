import { ListItem, ListItemText } from "@material-ui/core";
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
}

export const ListItemLink: React.FC<ListItemLinkProps> = props => {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
      <ListItem button component={renderLink}>
        {icon ? <>{icon}</> : null}
        <ListItemText primary={primary} />
      </ListItem>
  );
};
